// Hero.js
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

function Hero() {
    useEffect(() => {
        // No complex animations - keeping it super simple
    }, []);

    return (
        <div className="flex flex-col">
            {/* Hero section */}
            <section className="h-screen relative bg-white overflow-hidden flex flex-col items-center justify-center">
                {/* Development badge */}
                <div className="absolute top-20 w-full flex justify-center">
                    <div className="inline-block px-4 py-1.5 rounded-full border border-amber-400 bg-amber-50">
                        <span className="text-sm font-medium text-amber-800">In Development - Coming Soon</span>
                    </div>
                </div>

                {/* Simple centered GIF */}
                <div className="text-center">
                    <img
                        src="/hero_transparent.gif"
                        alt="Rotating Text"
                        className="w-72 h-72 object-contain mb-8"
                    />
                </div>

                {/* Simple CTA buttons */}
                <div className="mt-8">
                    <div className="flex flex-wrap gap-4 justify-center">
                        <Link
                            to="/events"
                            className="px-8 py-3 border border-gray-900 bg-gray-900 text-white hover:bg-gray-800 font-medium rounded-md transition-colors"
                        >
                            Events
                        </Link>
                        <Link
                            to="/map"
                            className="px-8 py-3 border border-gray-900 hover:bg-gray-100 text-gray-900 font-medium rounded-md transition-colors"
                        >
                            Map
                        </Link>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Hero;
