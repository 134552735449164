import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import Header from './components/Header';
import Footer from './components/Footer';
import EventsPage from './components/EventsPage';
import EventDetailPage from './components/EventDetailPage';
import MapPage from './components/MapPage';
import Hero from './components/Hero';
import ErrorPage from './components/ErrorPage';
import LoginPage from './components/LoginPage';
import ProfilePage from './components/ProfilePage';
import LoginCallback from './components/LoginCallback';

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // Data is fresh for 5 minutes
      cacheTime: 1000 * 60 * 30, // Cache data for 30 minutes
    },
  },
});

function App() {
  console.log("Debug: Rendering App component");

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <div className="flex flex-col min-h-screen">
          <Header />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<Hero />} />
              <Route path="/events" element={<EventsPage />} />
              <Route path="/events/:eventId" element={<EventDetailPage />} />
              <Route path="/map" element={<MapPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/login/success" element={<LoginCallback />} />
              {/* Catch-all route for 404 errors */}
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
      <Analytics />
      <SpeedInsights />
    </QueryClientProvider>
  );
}

export default App;
