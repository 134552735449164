// src/api/events.js
const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://api.was-lauft.com';

// Function to ensure image URLs have the correct format
export const ensureFullImageUrl = (imageUrl) => {
  const FALLBACK_IMAGE = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9IiNmMGYwZjAiLz48dGV4dCB4PSI1MCUiIHk9IjUwJSIgZm9udC1mYW1pbHk9InNhbnMtc2VyaWYiIGZvbnQtc2l6ZT0iMTQiIHRleHQtYW5jaG9yPSJtaWRkbGUiIGRvbWluYW50LWJhc2VsaW5lPSJtaWRkbGUiIGZpbGw9IiM5OTkiPk5vIEltYWdlPC90ZXh0Pjwvc3ZnPg==';

  if (!imageUrl) return FALLBACK_IMAGE;

  // If the URL already starts with http:// or https://, return it as is
  if (imageUrl.startsWith('http://') || imageUrl.startsWith('https://')) {
    return imageUrl;
  }

  // If it's an absolute path starting with /
  if (imageUrl.startsWith('/')) {
    return `${window.location.protocol}//${window.location.host}${imageUrl}`;
  }

  // If it includes the domain without protocol
  if (imageUrl.startsWith('api.was-lauft.com/')) {
    return `https://${imageUrl}`;
  }

  // Otherwise, assume it's a relative path to the API
  return `https://${API_BASE_URL.replace(/^https?:\/\//, '')}/${imageUrl}`;
};

// Fetch events with pagination and filters
export const fetchEvents = async ({ pageParam = 1, categories = [], page_size = 12 }) => {
  const categoryParam = categories.length > 0
    ? `&category=${categories.join(',')}`
    : '';

  const url = `${API_BASE_URL}/api/v1/events/future?page=${pageParam}&page_size=${page_size}${categoryParam}`;

  try {
    console.log(`Fetching events: page ${pageParam}, categories: ${categories.join(',') || 'none'}`);

    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 10000); // 10 second timeout

    const response = await fetch(url, {
      signal: controller.signal
    });

    clearTimeout(timeoutId);

    if (!response.ok) {
      throw new Error(`API error: ${response.status}`);
    }

    const data = await response.json();
    console.log(`Received ${data.events.length} events, page ${data.current_page} of ${data.total_pages}`);

    // Process images to ensure proper URLs
    data.events = data.events.map(event => ({
      ...event,
      image_url: event.image_url ? ensureFullImageUrl(event.image_url) : null
    }));

    return data;
  } catch (error) {
    console.error('Error fetching events:', error);
    throw error;
  }
};

// Fetch a single event by ID
export const fetchEventById = async (eventId) => {
  const url = `${API_BASE_URL}/api/v1/events/${eventId}`;

  const response = await fetch(url);

  if (!response.ok) {
    if (response.status === 404) {
      throw new Error("Event not found");
    }
    throw new Error(`API error: ${response.status}`);
  }

  const data = await response.json();

  // Process image URL
  if (data.image_url) {
    data.image_url = ensureFullImageUrl(data.image_url);
  }

  return data;
};

// Constants
export const FALLBACK_IMAGE = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9IiNmMGYwZjAiLz48dGV4dCB4PSI1MCUiIHk9IjUwJSIgZm9udC1mYW1pbHk9InNhbnMtc2VyaWYiIGZvbnQtc2l6ZT0iMTQiIHRleHQtYW5jaG9yPSJtaWRkbGUiIGRvbWluYW50LWJhc2VsaW5lPSJtaWRkbGUiIGZpbGw9IiM5OTkiPk5vIEltYWdlPC90ZXh0Pjwvc3ZnPg==';
