import React from 'react';

function Footer() {
    return (
        <footer className="bg-black text-white py-8 px-6">
            <div className="max-w-4xl mx-auto">
                {/* First row: Logo and development status */}
                <div className="flex justify-between items-center mb-6">
                    <div className="flex items-center gap-2">
                        <span className="font-bold text-xl">WasLauft</span>
                        <span className="px-2 py-1 text-xs rounded-full bg-gray-800 text-gray-400">
                            Beta
                        </span>
                    </div>

                    <div className="flex items-center gap-2 text-sm text-gray-400">
                        <span className="w-2 h-2 rounded-full bg-green-500 animate-pulse"></span>
                        In Development
                    </div>
                </div>

                {/* Second row: Social icons, copyright, and email */}
                <div className="flex flex-col-reverse md:flex-row items-center justify-between gap-4">
                    <div className="text-sm text-gray-400">
                        © 2025 WasLauft | <a href="mailto:business@was-lauft.com" className="hover:text-gray-300 transition-colors">business@was-lauft.com</a>
                    </div>

                    {/* Social Media Icons - Centered */}
                    <div className="flex items-center gap-4">
                        <a
                            href="https://www.instagram.com/waslauft.png/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-white hover:text-gray-300 transition-colors"
                            aria-label="Instagram"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                                <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                                <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                            </svg>
                        </a>
                        <a
                            href="https://www.tiktok.com/@waslauft.png"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-white hover:text-gray-300 transition-colors"
                            aria-label="TikTok"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="20"
                                viewBox="0 0 448 512"
                                fill="currentColor"
                            >
                                <path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
