import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import MapComponent from './MapComponent';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';

// API base URL
const API_URL = process.env.REACT_APP_API_URL || 'https://api.was-lauft.com';

function MapPage() {
  const [events, setEvents] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(new Set());
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchExpanded, setSearchExpanded] = useState(false);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [mapCenter, setMapCenter] = useState(null);

  const searchRef = useRef(null);
  const filtersRef = useRef(null);
  const eventDetailsRef = useRef(null);

  useEffect(() => {
    // Load map coordinates data
    const fetchMapCoordinates = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${API_URL}/api/v1/events/map-coordinates`);
        const mapData = response.data;

        // Set events data
        setEvents(mapData);

        // Initial filtering
        filterEvents('', new Set(), mapData);

        // Extract unique categories
        const categories = new Set();
        mapData.forEach(event => {
          if (event.category) {
            event.category.split(', ').forEach(cat => categories.add(cat));
          }
        });
        setUniqueCategories(Array.from(categories).sort());

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching map coordinates:', error);
        setIsLoading(false);
      }
    };

    fetchMapCoordinates();

    // Hide footer for this page
    const footer = document.querySelector('footer');
    if (footer) {
      footer.style.display = 'none';
    }

    // Add click outside listener
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSearchExpanded(false);
      }
      if (filtersRef.current && !filtersRef.current.contains(event.target)) {
        setFiltersVisible(false);
      }
      // Don't close event details on click outside
    };

    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      // Restore footer when leaving this page
      if (footer) {
        footer.style.display = '';
      }
    };
  }, []);

  // Load detailed event data when a marker is clicked
  const loadEventDetails = async (eventId) => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${API_URL}/api/v1/events/${eventId}`);
      const eventData = response.data;

      // Fix the image URL by adding https:// if it doesn't have it
      if (eventData.image_url && !eventData.image_url.startsWith('http')) {
        eventData.image_url = `https://${eventData.image_url}`;
      }

      setSelectedEvent(eventData);
      setIsLoading(false);
    } catch (error) {
      console.error(`Error fetching event details for ID ${eventId}:`, error);
      setIsLoading(false);
    }
  };

  const handleMarkerClick = (eventId, lat, lng) => {
    // Store the current map center and zoom when a marker is clicked
    setMapCenter([lat, lng]);
    loadEventDetails(eventId);
  };

  const closeEventDetails = () => {
    setSelectedEvent(null);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    filterEvents(value, selectedCategories, events);
  };

  const handleCategorySelect = (category) => {
    const newSelectedCategories = new Set(selectedCategories);
    if (newSelectedCategories.has(category)) {
      newSelectedCategories.delete(category);
    } else {
      newSelectedCategories.add(category);
    }
    setSelectedCategories(newSelectedCategories);
    filterEvents(searchTerm, newSelectedCategories, events);
  };

  const filterEvents = (searchTerm, categories, eventsData) => {
    const filtered = eventsData.filter(event => {
      const searchMatch =
        event.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        event.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (event.date && event.date.toLowerCase().includes(searchTerm.toLowerCase()));

      const categoryMatch = categories.size === 0 ||
        (event.category && event.category.split(', ').some(cat => categories.has(cat)));

      // Only include events with valid coordinates
      const hasCoordinates =
        event.latitude &&
        event.longitude &&
        !isNaN(event.latitude) &&
        !isNaN(event.longitude);

      return searchMatch && categoryMatch && hasCoordinates;
    });
    setFilteredEvents(filtered);
  };

  // Format date for display (handling European DD.MM.YYYY format)
  const formatDate = (dateString) => {
    if (!dateString) return '';

    try {
      const [day, month, year] = dateString.split('.');
      const date = new Date(`${year}-${month}-${day}`);

      if (isNaN(date.getTime())) {
        // Fallback if date is invalid
        return dateString;
      }

      const options = {
        weekday: 'short',
        month: 'short',
        day: 'numeric'
      };
      return date.toLocaleDateString('en-US', options);
    } catch (error) {
      console.error('Error formatting date:', error);
      return dateString;
    }
  };

  const clearAllFilters = () => {
    setSearchTerm('');
    setSelectedCategories(new Set());
    filterEvents('', new Set(), events);
  };

  if (isLoading && !selectedEvent) {
    return (
      <div className="flex justify-center items-center min-h-screen pt-24">
        <div className="flex space-x-2">
          <div className="w-3 h-3 bg-black rounded-full animate-pulse"></div>
          <div className="w-3 h-3 bg-black rounded-full animate-pulse delay-75"></div>
          <div className="w-3 h-3 bg-black rounded-full animate-pulse delay-150"></div>
        </div>
      </div>
    );
  }

  // Prepare markers for the map component with click handler
  const markers = filteredEvents.map(event => {
    // Fix image URL by adding https:// if it's missing
    let fixedImageUrl = event.image_url;
    if (fixedImageUrl && !fixedImageUrl.startsWith('http')) {
      fixedImageUrl = `https://${fixedImageUrl}`;
    }

    return {
      id: event.id,
      name: event.name,
      latitude: parseFloat(event.latitude),
      longitude: parseFloat(event.longitude),
      date: formatDate(event.date),
      address: event.address,
      location: event.location,
      category: event.category ? event.category.split(', ')[0] : '',
      // Use the fixed image URL
      image_url: fixedImageUrl,
      onClick: (id) => handleMarkerClick(id, parseFloat(event.latitude), parseFloat(event.longitude))
    };
  });

  return (
    <div className="h-screen w-screen fixed top-0 left-0 pt-16 overflow-hidden">
      {/* Floating control bar */}
      <div className="absolute top-20 z-20 left-0 right-0 px-4">
        <div className="max-w-screen-xl mx-auto">
          <div className={`flex items-center transition-all duration-300 ${searchExpanded ? 'opacity-0 pointer-events-none' : 'opacity-100'}`}>
            {/* Search button */}
            <button
              onClick={() => {
                setSearchExpanded(true);
                setFiltersVisible(false);
              }}
              className="bg-white rounded-full p-2.5 shadow-md mr-2 focus:outline-none hover:bg-gray-50"
              aria-label="Search"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </button>

            {/* Filter button with counter */}
            <button
              onClick={() => {
                setFiltersVisible(!filtersVisible);
                setSearchExpanded(false);
              }}
              className="bg-white rounded-full p-2.5 shadow-md mr-2 focus:outline-none hover:bg-gray-50 relative"
              aria-label="Filters"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
              </svg>
              {selectedCategories.size > 0 && (
                <span className="absolute -top-1 -right-1 bg-black text-white text-xs w-5 h-5 flex items-center justify-center rounded-full">
                  {selectedCategories.size}
                </span>
              )}
            </button>

            {/* Grid view button */}
            <Link
              to="/events"
              className="bg-white rounded-full p-2.5 shadow-md focus:outline-none hover:bg-gray-50"
              aria-label="Grid View"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zm10 0a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zm10 0a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
              </svg>
            </Link>

            {/* Event counter */}
            <div className="bg-black text-white text-xs px-3 py-1.5 rounded-full ml-auto shadow-md">
              {filteredEvents.length} events
            </div>
          </div>

          {/* Expanded search */}
          <div
            ref={searchRef}
            className={`transition-all duration-300 ${searchExpanded ? 'opacity-100 transform-none' : 'opacity-0 -translate-y-10 pointer-events-none'}`}
          >
            <div className="bg-white rounded-full shadow-md flex items-center overflow-hidden">
              <input
                type="text"
                placeholder="Search events..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="p-2.5 flex-grow border-none focus:ring-0 focus:outline-none pl-4"
                autoFocus={searchExpanded}
              />
              {searchTerm && (
                <button
                  onClick={() => {
                    setSearchTerm('');
                    filterEvents('', selectedCategories, events);
                  }}
                  className="p-2.5 text-gray-400 hover:text-gray-600"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              )}
              <button
                onClick={() => setSearchExpanded(false)}
                className="p-2.5 text-gray-700 hover:text-gray-900 bg-gray-100"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Filter panel */}
      <div
        ref={filtersRef}
        className={`absolute z-20 left-0 right-0 px-4 transition-all duration-300 ${filtersVisible ? 'top-32 opacity-100' : 'top-20 opacity-0 pointer-events-none'}`}
      >
        <div className="max-w-screen-xl mx-auto">
          <div className="bg-white rounded-lg shadow-md p-4">
            <div className="flex justify-between items-center mb-3">
              <h3 className="font-medium">Filter by category</h3>
              <button
                onClick={() => setFiltersVisible(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            <div className="flex flex-wrap gap-2 mb-3">
              {uniqueCategories.map(category => (
                <button
                  key={category}
                  onClick={() => handleCategorySelect(category)}
                  className={`px-3 py-1 rounded-full text-sm transition-all duration-200 ${selectedCategories.has(category)
                    ? 'bg-black text-white'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                  style={{
                    fontWeight: selectedCategories.has(category) ? '500' : '400',
                  }}
                >
                  {category}
                </button>
              ))}
            </div>

            {selectedCategories.size > 0 && (
              <button
                onClick={clearAllFilters}
                className="text-xs text-gray-500 hover:text-black"
              >
                Clear all filters
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Event details panel */}
      {selectedEvent && (
        <div
          ref={eventDetailsRef}
          className="absolute right-4 top-32 z-30 w-full max-w-md bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300"
          style={{
            maxHeight: 'calc(100vh - 180px)',
            overflowY: 'auto',
            width: window.innerWidth < 768 ? 'calc(100% - 32px)' : undefined
          }}
        >
          <div className="relative">
            {/* Close button */}
            <button
              onClick={closeEventDetails}
              className="absolute top-4 right-4 bg-white rounded-full p-1 shadow-md z-10"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>

            {/* Event image */}
            {selectedEvent.image_url ? (
              <div className="h-36 sm:h-48 overflow-hidden">
                <img
                  src={selectedEvent.image_url}
                  alt={selectedEvent.name}
                  className="w-full h-full object-cover"
                  onError={(e) => {
                    console.log("Image failed to load:", selectedEvent.image_url);
                    e.target.onerror = null; // Prevent infinite loop
                    e.target.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='200' viewBox='0 0 400 200'%3E%3Crect width='400' height='200' fill='%23cccccc'/%3E%3Ctext x='50%25' y='50%25' dominant-baseline='middle' text-anchor='middle' font-family='Arial' font-size='14' fill='%23333333'%3ENo Image%3C/text%3E%3C/svg%3E";
                  }}
                />
              </div>
            ) : (
              <div className="h-36 sm:h-48 bg-gray-200 flex items-center justify-center">
                <span className="text-gray-400">No image available</span>
              </div>
            )}

            {/* Date badge */}
            {selectedEvent.date && (
              <div className="absolute bottom-0 right-0 bg-black text-white py-1 px-3">
                <div className="text-xs uppercase tracking-wider">Date</div>
                <div className="font-medium">{formatDate(selectedEvent.date)}</div>
              </div>
            )}
          </div>

          <div className="p-3 sm:p-5">
            {/* Event header with categories */}
            <div className="border-l-4 border-black pl-3 py-1 mb-3 sm:mb-4">
              <h1 className="text-lg sm:text-xl font-bold">{selectedEvent.name}</h1>

              {selectedEvent.category && (
                <div className="flex flex-wrap gap-1 mt-2">
                  {selectedEvent.category.split(', ').map(cat => (
                    <span
                      key={cat}
                      className="inline-block bg-gray-900 text-white text-xs px-2 py-0.5 uppercase tracking-wider"
                    >
                      {cat}
                    </span>
                  ))}
                </div>
              )}
            </div>

            {/* Event details */}
            <div className="grid grid-cols-2 gap-2 sm:gap-4 mb-3 sm:mb-4">
              {/* When */}
              <div className="bg-gray-50 p-2 sm:p-3 border-t-2 border-black">
                <h2 className="font-bold text-sm mb-1">When</h2>
                <div className="space-y-1 text-gray-800 text-sm">
                  <p>{formatDate(selectedEvent.date)}</p>
                  {selectedEvent.time && <p>{selectedEvent.time}</p>}
                </div>
              </div>

              {/* Where */}
              <div className="bg-gray-50 p-2 sm:p-3 border-t-2 border-black">
                <h2 className="font-bold text-sm mb-1">Where</h2>
                <div className="space-y-1 text-gray-800 text-sm">
                  <p className="font-medium">{selectedEvent.location}</p>
                  {selectedEvent.address && <p>{selectedEvent.address}</p>}
                </div>
              </div>
            </div>

            {/* Price */}
            {selectedEvent.preis && (
              <div className="bg-gray-50 p-2 sm:p-3 border-t-2 border-black mb-3 sm:mb-4">
                <h2 className="font-bold text-sm mb-1">Price</h2>
                <p className="text-gray-800 text-sm">{selectedEvent.preis}</p>
              </div>
            )}

            {/* Description preview */}
            {selectedEvent.description && (
              <div className="mb-4">
                <h2 className="font-bold text-sm mb-2">Description</h2>
                <p className="text-sm text-gray-700 line-clamp-3">{selectedEvent.description}</p>
              </div>
            )}

            {/* Link to full details */}
            <div className="sticky bottom-0 pb-3 pt-2 bg-white">
              <Link
                to={`/events/${selectedEvent.id}`}
                className="block w-full bg-black text-white text-center py-2 px-4 rounded hover:bg-gray-800 transition-colors"
              >
                View Full Details
              </Link>
            </div>
          </div>
        </div>
      )}

      {/* Full-screen map */}
      <div className="w-full h-full z-10">
        <MapComponent
          markers={markers}
          height="100%"
          showPopups={true}
          fullscreenStyle={true}
          center={mapCenter}
        />
      </div>

      {/* Empty state */}
      {filteredEvents.length === 0 && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg shadow-md text-center z-20">
          <h3 className="text-xl font-medium text-gray-700 mb-2">No events found</h3>
          <p className="text-gray-500 mb-4">Try adjusting your search or filters</p>
          <button
            onClick={clearAllFilters}
            className="px-4 py-2 bg-black text-white rounded-md"
          >
            Reset Filters
          </button>
        </div>
      )}
    </div>
  );
}

export default MapPage;
