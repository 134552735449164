// Header.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 20);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Prevent scrolling when menu is open
    useEffect(() => {
        if (isMenuOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isMenuOpen]);

    return (
        <header className={`fixed w-full z-50 transition-all duration-300 ${isScrolled && !isMenuOpen ? 'bg-white/90 backdrop-blur-md shadow-sm' : 'bg-transparent'
            }`}>
            <div className="max-w-7xl mx-auto px-6 py-4">
                <div className="flex items-center justify-between">
                    <Link
                        to="/"
                        className="text-2xl font-bold hover:opacity-70 transition-opacity"
                    >
                        WasLauft
                    </Link>

                    <button
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        className="flex flex-col items-center justify-center gap-1.5 group z-50 relative w-8 h-8"
                        aria-label="Menu"
                    >
                        <span className={`
                            absolute h-0.5 bg-black transform transition-all duration-300 ease-in-out
                            ${isMenuOpen ? 'w-8 rotate-45' : 'w-5 group-hover:w-6 translate-y-[-4px]'}
                        `} />
                        <span className={`
                            absolute w-8 h-0.5 bg-black transform transition-all duration-300 ease-in-out
                            ${isMenuOpen ? 'opacity-0' : 'opacity-100'}
                        `} />
                        <span className={`
                            absolute h-0.5 bg-black transform transition-all duration-300 ease-in-out
                            ${isMenuOpen ? 'w-8 -rotate-45' : 'w-5 group-hover:w-6 translate-y-[4px]'}
                        `} />
                    </button>
                </div>
            </div>

            {/* Fullscreen menu */}
            <nav className={`
                fixed top-0 left-0 w-full h-full bg-white z-40
                transform transition-all duration-500 ease-in-out
                flex items-center justify-center
                ${isMenuOpen ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-full pointer-events-none'}
            `}>
                <ul className="text-2xl space-y-8 text-center px-4">
                    <li className={`
                        transform transition-all duration-300 ease-in-out delay-100
                        ${isMenuOpen ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}
                    `}>
                        <Link
                            to="/"
                            className="hover:opacity-70 transition-opacity block py-2"
                            onClick={() => setIsMenuOpen(false)}
                        >
                            Home
                        </Link>
                        <Link
                            to="/events"
                            className="hover:opacity-70 transition-opacity block py-2"
                            onClick={() => setIsMenuOpen(false)}
                        >
                            Events
                        </Link>
                        <Link
                            to="/map"
                            className="hover:opacity-70 transition-opacity block py-2"
                            onClick={() => setIsMenuOpen(false)}
                        >
                            Map
                        </Link>

                        <Link
                            to="/login"
                            className="hover:opacity-70 transition-opacity block py-2"
                            onClick={() => setIsMenuOpen(false)}
                        >
                            Login
                        </Link>

                    </li>
                </ul>
            </nav>
        </header>
    );
}
