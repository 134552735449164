// src/components/EventsPage.js
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';
import { fetchEvents, FALLBACK_IMAGE } from '../api/events';
import './masonry-direct.css';

function EventsPage() {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [searchExpanded, setSearchExpanded] = useState(false);
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [failedImages, setFailedImages] = useState(new Set());
    const [itemHeights, setItemHeights] = useState({});
    const [columnCount, setColumnCount] = useState(4); // Default column count

    const searchRef = useRef(null);
    const filtersRef = useRef(null);
    const lastEventRef = useRef(null);
    const containerRef = useRef(null);

    // Update column count based on viewport width
    useEffect(() => {
        const updateColumnCount = () => {
            const width = window.innerWidth;
            if (width < 640) setColumnCount(1);
            else if (width < 768) setColumnCount(2);
            else if (width < 1024) setColumnCount(3);
            else setColumnCount(4);
        };

        updateColumnCount();
        window.addEventListener('resize', updateColumnCount);
        return () => window.removeEventListener('resize', updateColumnCount);
    }, []);

    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        refetch
    } = useInfiniteQuery({
        queryKey: ['events', selectedCategories],
        queryFn: ({ pageParam = 1 }) => fetchEvents({
            pageParam,
            categories: selectedCategories
        }),
        getNextPageParam: (lastPage) => {
            console.log('getNextPageParam:', lastPage);
            return lastPage.current_page < lastPage.total_pages ? lastPage.current_page + 1 : undefined;
        },
    });

    const events = data ? data.pages.flatMap(page => page.events) : [];
    const filteredEvents = searchTerm
        ? events.filter(event =>
            event.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (event.location && event.location.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (event.date && event.date.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (event.description && event.description.toLowerCase().includes(searchTerm.toLowerCase()))
        )
        : events;

    // Distribute events into columns (horizontally)
    const distributeEventsIntoColumns = (events, columnCount) => {
        const columns = Array(columnCount).fill().map(() => []);

        events.forEach((event, index) => {
            const columnIndex = index % columnCount;
            columns[columnIndex].push(event);
        });

        return columns;
    };

    const columns = distributeEventsIntoColumns(filteredEvents, columnCount);

    const uniqueCategories = [...new Set(
        events.filter(event => event.category).flatMap(event => event.category.split(', '))
    )];

    const handleCategorySelect = (category) => {
        setSelectedCategories(prev => prev.includes(category) ? prev.filter(cat => cat !== category) : [...prev, category]);
    };

    const handleSearchChange = (e) => setSearchTerm(e.target.value);

    const clearAllFilters = () => {
        setSearchTerm('');
        setSelectedCategories([]);
        refetch();
    };

    const handleHeightMeasured = useCallback((eventId, height) => {
        setItemHeights(prev => {
            if (prev[eventId] !== height) {
                return { ...prev, [eventId]: height };
            }
            return prev;
        });
    }, []);

    const averageHeight = Object.values(itemHeights).length > 0
        ? Object.values(itemHeights).reduce((sum, height) => sum + height, 0) / Object.values(itemHeights).length
        : 300;

    // Update the observer setup
    useEffect(() => {
        if (!hasNextPage || isFetchingNextPage || isLoading) return;

        const observer = new IntersectionObserver(
            (entries) => {
                // Add more debugging
                console.log('Observer triggered:', {
                    isIntersecting: entries[0].isIntersecting,
                    hasNextPage,
                    isFetchingNextPage,
                    boundingClientRect: entries[0].boundingClientRect,
                    rootBounds: entries[0].rootBounds
                });

                if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
                    console.log('Loading next page...');
                    fetchNextPage();
                }
            },
            { threshold: 0.1, rootMargin: '200px 0px' }
        );

        const currentLastEvent = lastEventRef.current;
        if (currentLastEvent) {
            observer.observe(currentLastEvent);
            console.log('Observing last event:', currentLastEvent);
        } else {
            console.log('No last event ref found to observe');
        }

        return () => {
            if (currentLastEvent) {
                observer.unobserve(currentLastEvent);
            }
        };
    }, [fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, filteredEvents]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) setSearchExpanded(false);
            if (filtersRef.current && !filtersRef.current.contains(event.target)) setFiltersVisible(false);
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const formatDate = (dateString) => {
        if (!dateString) return 'Date TBD';
        try {
            const [day, month, year] = dateString.split('.');
            const date = new Date(`${year}-${month}-${day}`);
            if (isNaN(date.getTime())) return dateString;
            return date.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' });
        } catch (error) {
            console.error('Error formatting date:', error);
            return dateString;
        }
    };

    const getContrastingColor = (categoryName) => {
        if (!categoryName) return 'hsl(0, 0%, 85%)';
        const hash = categoryName.split('').reduce((acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0);
        const hue = Math.abs(hash) % 360;
        return `hsl(${hue}, 70%, 85%)`;
    };

    const handleImageError = useCallback((eventId) => {
        setFailedImages(prev => new Set(prev).add(eventId));
    }, []);

    const EventItem = ({ event, isLast, onHeightMeasured, lastEventRef, failedImages, handleImageError }) => {
        const itemRef = useRef(null);

        useEffect(() => {
            if (itemRef.current) {
                const height = itemRef.current.getBoundingClientRect().height;
                onHeightMeasured(event.id, height);
            }
        }, [event.id, onHeightMeasured]); // Only runs when event.id or onHeightMeasured changes

        return (
            <div
                className="masonry-item"
                ref={node => {
                    itemRef.current = node;
                    if (isLast) lastEventRef.current = node;
                }}
            >
                <Link
                    to={`/events/${event.id}`}
                    onClick={(e) => {
                        console.log('Navigating to:', `/events/${event.id}`);
                    }}
                    className="group block relative"
                >
                    <div className="relative overflow-hidden bg-gray-100">
                        <img
                            src={failedImages.has(event.id) ? FALLBACK_IMAGE : (event.image_url || FALLBACK_IMAGE)}
                            alt={event.name}
                            className="w-full transition-transform duration-700 ease-out group-hover:scale-105"
                            onError={(e) => {
                                console.log("Image error:", event.image_url);
                                e.target.src = FALLBACK_IMAGE;
                                e.target.onError = null;
                                handleImageError(event.id);
                            }}
                            loading="lazy"
                        />
                        <div className="absolute top-4 left-4 bg-black text-white text-xs uppercase tracking-wider px-3 py-1 font-medium">
                            {formatDate(event.date)}
                        </div>
                        {event.category && event.category.split(', ')[0] && (
                            <div
                                className="absolute bottom-4 right-4 px-3 py-1 text-xs font-medium uppercase tracking-wider"
                                style={{ backgroundColor: getContrastingColor(event.category.split(', ')[0]), color: '#000' }}
                            >
                                {event.category.split(', ')[0]}
                            </div>
                        )}
                    </div>
                    <div className="py-3 space-y-0.5">
                        <h3 className="text-base font-medium text-gray-900 leading-tight group-hover:underline decoration-1 underline-offset-2">
                            {event.name}
                        </h3>
                        <div className="flex items-center text-gray-500 text-sm">
                            <span>{event.location}</span>
                        </div>
                    </div>
                </Link>
            </div>
        );
    };

    if (isLoading && filteredEvents.length === 0) {
        return (
            <div className="flex justify-center items-center min-h-screen pt-24">
                <div className="flex space-x-2">
                    <div className="w-3 h-3 bg-black rounded-full animate-pulse"></div>
                    <div className="w-3 h-3 bg-black rounded-full animate-pulse delay-75"></div>
                    <div className="w-3 h-3 bg-black rounded-full animate-pulse delay-150"></div>
                </div>
            </div>
        );
    }

    if (error && filteredEvents.length === 0) {
        return (
            <div className="flex flex-col justify-center items-center min-h-screen pt-24">
                <div className="bg-red-50 text-red-800 p-4 rounded-lg shadow max-w-md">
                    <h3 className="text-xl font-medium mb-2">Error loading events</h3>
                    <p>{error.message}</p>
                    <button onClick={clearAllFilters} className="mt-4 bg-red-800 text-white px-4 py-2 rounded hover:bg-red-900">
                        Try Again
                    </button>
                </div>
            </div>
        );
    }

    return (
        <main className="bg-[#f7f7f7] min-h-screen pt-24 pb-12">
            <div className="px-4 max-w-screen-xl mx-auto">
                <div className="mb-6">
                    <div className="flex justify-between items-center">
                        <h2 className="text-2xl sm:text-3xl font-bold text-gray-900">Discover Events</h2>
                        <div className="flex items-center">
                            <div className={`flex items-center transition-all duration-300 ${searchExpanded ? 'opacity-0 pointer-events-none' : 'opacity-100'}`}>
                                <button onClick={() => { setSearchExpanded(true); setFiltersVisible(false); }} className="bg-white rounded-full p-2.5 shadow-md mr-2 focus:outline-none hover:bg-gray-50" aria-label="Search">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                    </svg>
                                </button>
                                <button onClick={() => { setFiltersVisible(!filtersVisible); setSearchExpanded(false); }} className="bg-white rounded-full p-2.5 shadow-md mr-2 focus:outline-none hover:bg-gray-50 relative" aria-label="Filters">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
                                    </svg>
                                    {selectedCategories.length > 0 && (
                                        <span className="absolute -top-1 -right-1 bg-black text-white text-xs w-5 h-5 flex items-center justify-center rounded-full">
                                            {selectedCategories.length}
                                        </span>
                                    )}
                                </button>
                                <Link to="/map" className="bg-white rounded-full p-2.5 shadow-md focus:outline-none hover:bg-gray-50" aria-label="Map View">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />
                                    </svg>
                                </Link>
                                {(selectedCategories.length > 0 || searchTerm) && (
                                    <div className="bg-black text-white text-xs px-3 py-1.5 rounded-full ml-2">
                                        {filteredEvents.length} events
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div ref={searchRef} className={`mt-4 transition-all duration-300 ${searchExpanded ? 'opacity-100 transform-none' : 'opacity-0 -translate-y-5 pointer-events-none'}`}>
                        <div className="bg-white rounded-full shadow-md flex items-center overflow-hidden">
                            <input
                                type="text"
                                placeholder="Search events..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                                className="p-2.5 flex-grow border-none focus:ring-0 focus:outline-none pl-4"
                                autoFocus={searchExpanded}
                            />
                            {searchTerm && (
                                <button onClick={() => setSearchTerm('')} className="p-2.5 text-gray-400 hover:text-gray-600">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            )}
                            <button onClick={() => setSearchExpanded(false)} className="p-2.5 text-gray-700 hover:text-gray-900 bg-gray-100">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

                <div ref={filtersRef} className={`transition-all duration-300 mb-8 ${filtersVisible ? 'opacity-100 max-h-screen' : 'opacity-0 max-h-0 overflow-hidden pointer-events-none'}`}>
                    <div className="bg-white rounded-lg shadow-md p-4 mb-4">
                        <div className="flex justify-between items-center mb-3">
                            <h3 className="font-medium">Filter by category</h3>
                            <button onClick={() => setFiltersVisible(false)} className="text-gray-500 hover:text-gray-700">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        <div className="flex flex-wrap gap-2 mb-3">
                            {uniqueCategories.map(category => (
                                <button
                                    key={category}
                                    onClick={() => handleCategorySelect(category)}
                                    className={`px-3 py-1 rounded-full text-sm transition-all duration-200 ${selectedCategories.includes(category) ? 'bg-black text-white' : 'bg-gray-100 text-gray-700 hover:bg-gray-200'}`}
                                    style={{ fontWeight: selectedCategories.includes(category) ? '500' : '400' }}
                                >
                                    {category}
                                </button>
                            ))}
                        </div>
                        {selectedCategories.length > 0 && (
                            <button onClick={clearAllFilters} className="text-xs text-gray-500 hover:text-black">
                                Clear all filters
                            </button>
                        )}
                    </div>
                </div>

                {/* New horizontal masonry layout */}
                <div ref={containerRef} className="flex flex-row gap-4">
                    {columns.map((column, columnIndex) => (
                        <div key={`column-${columnIndex}`} className="flex-1 flex flex-col gap-4">
                            {column.map((event, eventIndex) => {
                                // The last event is the one at the bottom of the last column
                                const isLast = columnIndex === columns.length - 1 && eventIndex === column.length - 1;

                                return (
                                    <EventItem
                                        key={event.id}
                                        event={event}
                                        isLast={isLast}
                                        onHeightMeasured={handleHeightMeasured}
                                        lastEventRef={lastEventRef}
                                        failedImages={failedImages}
                                        handleImageError={handleImageError}
                                    />
                                );
                            })}

                            {/* Show placeholders in this column if loading more */}
                            {isFetchingNextPage && (
                                <div className="masonry-item" style={{ height: `${averageHeight}px`, backgroundColor: '#eee' }} />
                            )}
                        </div>
                    ))}

                    {/* Add empty columns if we're still loading and don't have all columns yet */}
                    {isFetchingNextPage && columns.length < columnCount &&
                        Array(columnCount - columns.length).fill(0).map((_, i) => (
                            <div key={`empty-column-${i}`} className="flex-1 flex flex-col gap-4">
                                <div className="masonry-item" style={{ height: `${averageHeight}px`, backgroundColor: '#eee' }} />
                            </div>
                        ))
                    }
                </div>

                {isFetchingNextPage && (
                    <div className="flex justify-center my-8">
                        <div className="flex space-x-2">
                            <div className="w-2 h-2 bg-black rounded-full animate-pulse"></div>
                            <div className="w-2 h-2 bg-black rounded-full animate-pulse delay-75"></div>
                            <div className="w-2 h-2 bg-black rounded-full animate-pulse delay-150"></div>
                        </div>
                    </div>
                )}

                {filteredEvents.length === 0 && !isLoading && (
                    <div className="text-center py-16">
                        <h3 className="text-xl font-medium text-gray-700 mb-2">No events found</h3>
                        <p className="text-gray-500">Try adjusting your search or filters</p>
                        <button onClick={clearAllFilters} className="mt-4 px-4 py-2 bg-black text-white rounded-md">
                            Reset Filters
                        </button>
                    </div>
                )}

                {filteredEvents.length > 0 && data && (
                    <div className="text-center text-gray-500 text-sm mt-8">
                        {hasNextPage ? <p>Scroll down to load more events</p> : <p>You've reached the end of the list</p>}
                    </div>
                )}
            </div>
        </main>
    );
}

export default EventsPage;
