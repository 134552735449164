import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, ZoomControl, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './custom-map.css';

// Create custom marker icon
const createCustomIcon = () => {
  return L.divIcon({
    className: 'custom-marker-icon',
    html: `<div class="marker-pin"></div>`,
    iconSize: [30, 42],
    iconAnchor: [15, 42],
    popupAnchor: [0, -42]
  });
};

// Create the custom icon once, outside of the component
const customIcon = createCustomIcon();

// Create a map controller component to handle center/zoom updates
const MapController = ({ center, zoom }) => {
  const map = useMap();
  const isInitialMount = useRef(true);

  useEffect(() => {
    // Skip the first render to prevent initial animation
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    if (center) {
      try {
        // Check if map is still valid before attempting to interact with it
        if (map && map._loaded) {
          map.setView(center, map.getZoom(), {
            animate: true
          });
        }
      } catch (error) {
        console.error("Map error when setting center:", error);
      }
    }
  }, [center, map]);

  useEffect(() => {
    if (zoom && !isInitialMount.current) {
      try {
        // Check if map is still valid before attempting to interact with it
        if (map && map._loaded) {
          map.setZoom(zoom);
        }
      } catch (error) {
        console.error("Map error when setting zoom:", error);
      }
    }
  }, [zoom, map]);

  return null;
};

// Component to handle marker click events
const MarkerWithClick = ({ marker, showPopups, fullscreenStyle }) => {
  const map = useMap();
  const [markerPosition] = useState([marker.latitude, marker.longitude]);
  const isMounted = useRef(true);

  // Set up cleanup function
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleMarkerClick = () => {
    try {
      // Only proceed if map is valid
      if (!map || !map._loaded) {
        console.log("Map not ready for interaction");
        return;
      }

      // Store the current position and zoom
      const currentZoom = map.getZoom();

      // First, execute the onClick handler if provided
      if (marker.onClick) {
        marker.onClick(marker.id);
      }

      // After a small delay, pan to the marker position but maintain the zoom level
      setTimeout(() => {
        // Check if component is still mounted and map is valid
        if (isMounted.current && map && map._loaded) {
          map.setView(markerPosition, currentZoom, {
            animate: true,
            duration: 0.5
          });
        }
      }, 100); // Slightly longer delay
    } catch (error) {
      console.error("Error handling marker click:", error);
    }
  };

  return (
    <Marker
      position={markerPosition}
      icon={customIcon}
      eventHandlers={{
        click: handleMarkerClick,
      }}
    >
      {showPopups && (
        <Popup className="custom-popup">
          <div className="event-popup">
            {/* Image Header - Show for all popup types */}
            {marker.image_url && (
              <div className="popup-image-container">
                <img
                  src={marker.image_url}
                  alt={marker.name}
                  className="popup-image"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='200' viewBox='0 0 400 200'%3E%3Crect width='400' height='200' fill='%23cccccc'/%3E%3C/svg%3E";
                  }}
                />
                <div className="popup-date-tag">
                  {marker.date}
                </div>
                {marker.category && (
                  <div className="popup-category-tag">
                    {marker.category}
                  </div>
                )}
              </div>
            )}

            <div className="popup-content">
              <h3 className="popup-title">{marker.name}</h3>

              {!fullscreenStyle && marker.date && (
                <div className="popup-date">{marker.date}</div>
              )}

              <div className="popup-location">
                <svg xmlns="http://www.w3.org/2000/svg" className="popup-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                <span>{marker.address || marker.location}</span>
              </div>

              <button
                onClick={(e) => {
                  e.stopPropagation(); // Prevent it from triggering marker click again
                  marker.onClick && marker.onClick(marker.id);
                }}
                className="popup-link"
              >
                View Details
                <svg xmlns="http://www.w3.org/2000/svg" className="popup-arrow" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
              </button>
            </div>
          </div>
        </Popup>
      )}
    </Marker>
  );
};

function MapComponent({
  markers = [],
  center,
  zoom = 13,
  height = "500px",
  showPopups = true,
  singleMode = false,
  fullscreenStyle = false
}) {
  const [mapInstance, setMapInstance] = useState(null);

  // Fix for Leaflet default icon
  useEffect(() => {
    // Make sure Leaflet's default icon images are properly set
    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
      iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
      shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
    });
  }, []);

  // Default Zürich center
  const ZURICH_CENTER = [47.3769, 8.5417];

  // If in single mode and we have one marker, use its coordinates as center
  const initialMapCenter = singleMode && markers.length === 1
    ? [markers[0].latitude, markers[0].longitude]
    : center;

  // Check if markers are widely spread (across continents)
  const isWidelySpread = () => {
    if (markers.length <= 1) return false;

    const latitudes = markers.map(marker => marker.latitude);
    const longitudes = markers.map(marker => marker.longitude);

    const minLat = Math.min(...latitudes);
    const maxLat = Math.max(...latitudes);
    const minLng = Math.min(...longitudes);
    const maxLng = Math.max(...longitudes);

    const latDiff = maxLat - minLat;
    const lngDiff = maxLng - minLng;

    // If difference is huge, markers are likely in different continents
    return (latDiff > 30 || lngDiff > 30);
  };

  // If no center is provided, and markers are not widely spread, calculate from markers
  // Otherwise default to Zürich center
  let calculatedCenter;
  if (initialMapCenter) {
    calculatedCenter = initialMapCenter;
  } else if (markers.length > 0 && !isWidelySpread()) {
    calculatedCenter = calculateCenter(markers);
  } else {
    calculatedCenter = ZURICH_CENTER;
  }

  // If in single mode, use the provided zoom
  // Otherwise if markers are widely spread, use Zürich default zoom
  // Otherwise calculate based on markers
  let calculatedZoom;
  if (singleMode) {
    calculatedZoom = zoom;
  } else if (markers.length > 1) {
    if (isWidelySpread()) {
      calculatedZoom = 12; // Default Zürich zoom
    } else {
      calculatedZoom = determineZoomLevel(markers);
    }
  } else {
    calculatedZoom = zoom;
  }

  // When the map is ready
  const whenCreated = (map) => {
    setMapInstance(map);
  };

  return (
    <div className={`custom-map-container ${fullscreenStyle ? 'fullscreen-map' : ''}`} style={{ height }}>
      <MapContainer
        center={calculatedCenter}
        zoom={calculatedZoom}
        zoomControl={false}
        scrollWheelZoom={true}
        className="h-full w-full"
        style={{ zIndex: 1 }}
        whenCreated={whenCreated}
      >
        {/* Monochrome map style */}
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
        />

        {/* Map controller for dynamic center/zoom updates - only add when map is ready */}
        {mapInstance && (
          <MapController center={center} zoom={zoom} />
        )}

        {/* Render all markers with click handling */}
        {markers.map((marker) => (
          <MarkerWithClick
            key={marker.id}
            marker={marker}
            showPopups={showPopups}
            fullscreenStyle={fullscreenStyle}
          />
        ))}

        {/* Custom zoom controls */}
        <ZoomControl position="bottomright" />
      </MapContainer>
    </div>
  );
}

// Helper function to calculate center point from multiple markers
function calculateCenter(markers) {
  if (markers.length === 0) return [47.3769, 8.5417]; // Default to Zürich

  const latitudes = markers.map(marker => marker.latitude);
  const longitudes = markers.map(marker => marker.longitude);

  const minLat = Math.min(...latitudes);
  const maxLat = Math.max(...latitudes);
  const minLng = Math.min(...longitudes);
  const maxLng = Math.max(...longitudes);

  return [(minLat + maxLat) / 2, (minLng + maxLng) / 2];
}

// Helper function to determine appropriate zoom level based on the spread of markers
function determineZoomLevel(markers) {
  if (markers.length <= 1) return 14;

  const latitudes = markers.map(marker => marker.latitude);
  const longitudes = markers.map(marker => marker.longitude);

  const minLat = Math.min(...latitudes);
  const maxLat = Math.max(...latitudes);
  const minLng = Math.min(...longitudes);
  const maxLng = Math.max(...longitudes);

  const latDiff = maxLat - minLat;
  const lngDiff = maxLng - minLng;
  const maxDiff = Math.max(latDiff, lngDiff);

  if (maxDiff > 1) return 8;      // Very spread out
  if (maxDiff > 0.5) return 9;    // Spread out
  if (maxDiff > 0.2) return 10;   // Moderate spread
  if (maxDiff > 0.1) return 11;   // Closer together
  if (maxDiff > 0.05) return 12;  // City level
  if (maxDiff > 0.01) return 13;  // Neighborhood level
  return 14;                      // Very close together
}

export default MapComponent;
