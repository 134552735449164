// src/components/ErrorPage.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const ErrorPage = () => {
  const [randomGif, setRandomGif] = useState('');

  useEffect(() => {
    // List of GIF filenames in the public/errors folder
    const errorGifs = [
      'error1.gif',
      // Add more GIFs as needed
    ];

    // Choose a random GIF
    const randomIndex = Math.floor(Math.random() * errorGifs.length);
    setRandomGif(errorGifs[randomIndex]);
  }, []);

  return (
    <main className="min-h-screen flex items-center justify-center p-6">
      <div className="relative w-full max-w-lg">
        <div className="text-center relative">
          {/* 404 text container with relative positioning */}
          <div className="relative">
            {/* 404 text */}
            <h1 className="text-[100px] sm:text-[120px] md:text-[180px] lg:text-[200px]
                           font-bold leading-none">
              404
            </h1>
          </div>

          {/* GIF container */}
          <div className="relative mx-auto w-24 h-24 md:w-32 md:h-32 overflow-hidden
                         -mt-4 md:-mt-8">
            <div className="relative -top-6">
              {randomGif && (
                <img
                  src={`/errors/${randomGif}`}
                  alt="Error GIF"
                  className="w-full h-full object-contain"
                />
              )}
            </div>
          </div>

          {/* Text and link */}
          <div className="mt-16 md:mt-24">
            <p className="text-lg md:text-xl italic mb-4">
              oops! this page is hanging around somewhere else
            </p>
            <Link
              to="/"
              className="text-sm opacity-70 hover:opacity-100 transition-opacity"
            >
              take me home
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ErrorPage;
