// src/components/EventDetailPage.js
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchEventById, FALLBACK_IMAGE } from '../api/events';
import MapComponent from './MapComponent';
import 'leaflet/dist/leaflet.css'; // Make sure this is imported


function EventDetailPage() {
    const { eventId } = useParams();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    // Try to get the event from the cache
    const cachedEvents = queryClient.getQueryData(['events']);
    const cachedEvent = cachedEvents?.events?.find(event => event.id === parseInt(eventId));

    // Fetch the event details, using the cached event as initial data
    const { data: eventDetails, isLoading, error } = useQuery({
        queryKey: ['event', eventId],
        queryFn: () => fetchEventById(eventId),
        initialData: cachedEvent,
        staleTime: 1000 * 60 * 5, // 5 minutes
    });


    // Add this to your EventDetailPage component
    useEffect(() => {
        // Scroll to top when component mounts
        window.scrollTo(0, 0);
    }, []);

    // Format date for display
    const formatDate = (dateString) => {
        if (!dateString) return 'Date TBD';

        try {
            const [day, month, year] = dateString.split('.');
            const date = new Date(`${year}-${month}-${day}`);

            if (isNaN(date.getTime())) {
                return dateString;
            }

            const options = {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            };
            return date.toLocaleDateString('en-US', options);
        } catch (error) {
            console.error('Error formatting date:', error);
            return dateString;
        }
    };

    // Navigate back to events
    const handleBackToEvents = () => {
        navigate('/events');
    };

    if (isLoading && !cachedEvent) {
        return (
            <div className="flex justify-center items-center min-h-screen pt-24">
                <div className="flex space-x-2">
                    <div className="w-3 h-3 bg-black rounded-full animate-pulse"></div>
                    <div className="w-3 h-3 bg-black rounded-full animate-pulse delay-75"></div>
                    <div className="w-3 h-3 bg-black rounded-full animate-pulse delay-150"></div>
                </div>
            </div>
        );
    }

    if (error || !eventDetails) {
        return (
            <div className="max-w-4xl mx-auto p-8 pt-24 text-center">
                <h1 className="text-2xl font-medium mb-4">Event not found</h1>
                <p className="mb-6 text-gray-600">The event you're looking for doesn't exist or has been removed.</p>
                <button
                    onClick={handleBackToEvents}
                    className="inline-block bg-black text-white px-6 py-2 hover:bg-gray-800"
                >
                    Back to Events
                </button>
            </div>
        );
    }

    // Prepare marker for map component
    const marker = {
        id: eventDetails.id,
        name: eventDetails.name,
        latitude: parseFloat(eventDetails.latitude),
        longitude: parseFloat(eventDetails.longitude),
        date: formatDate(eventDetails.date),
        address: eventDetails.address,
        location: eventDetails.location
    };

    // Only include the marker if coordinates are valid
    const hasValidCoordinates =
        eventDetails.latitude &&
        eventDetails.longitude &&
        !isNaN(eventDetails.latitude) &&
        !isNaN(eventDetails.longitude);

    return (
        <main className="bg-[#f7f7f7] min-h-screen pt-24 pb-12">
            {/* Back button */}
            <div className="max-w-6xl mx-auto px-4 mb-6">
                <button
                    onClick={handleBackToEvents}
                    className="flex items-center text-gray-700 hover:text-black"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M19 12H5M12 19l-7-7 7-7" />
                    </svg>
                    All Events
                </button>
            </div>

            <div className="max-w-6xl mx-auto px-4">
                {/* Split layout */}
                <div className="grid grid-cols-1 lg:grid-cols-5 gap-8">
                    {/* Left column - event info */}
                    <div className="lg:col-span-2 space-y-8 order-2 lg:order-1">
                        {/* Event header */}
                        <div className="border-l-4 border-black pl-4 py-2">
                            <h1 className="text-3xl font-bold mb-2">{eventDetails.name}</h1>

                            {/* Styled category pills */}
                            {eventDetails.category && (
                                <div className="flex flex-wrap gap-2 mt-4">
                                    {eventDetails.category.split(', ').map(cat => (
                                        <span
                                            key={cat}
                                            className="inline-block bg-gray-900 text-white text-xs px-3 py-1 uppercase tracking-wider"
                                        >
                                            {cat}
                                        </span>
                                    ))}
                                </div>
                            )}
                        </div>

                        {/* Event details blocks */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            {/* When */}
                            <div className="bg-white p-5 border-t-2 border-black">
                                <h2 className="font-bold text-lg mb-1">When</h2>
                                <div className="space-y-1 text-gray-800">
                                    <p>{formatDate(eventDetails.date)}</p>
                                    {eventDetails.time && <p>{eventDetails.time}</p>}
                                </div>
                            </div>

                            {/* Where */}
                            <div className="bg-white p-5 border-t-2 border-black">
                                <h2 className="font-bold text-lg mb-1">Where</h2>
                                <div className="space-y-1 text-gray-800">
                                    <p className="font-medium">{eventDetails.location}</p>
                                    {eventDetails.address && <p>{eventDetails.address}</p>}
                                </div>
                            </div>

                            {/* Price */}
                            {eventDetails.preis && (
                                <div className="bg-white p-5 border-t-2 border-black">
                                    <h2 className="font-bold text-lg mb-1">Price</h2>
                                    <p className="text-gray-800">{eventDetails.preis}</p>
                                </div>
                            )}
                        </div>

                        {/* Description */}
                        {eventDetails.description && (
                            <div className="bg-white p-6 border-l border-r border-b border-gray-200">
                                <h2 className="font-bold text-xl mb-4 pb-2 border-b border-gray-100">About This Event</h2>
                                <p className="text-gray-700 leading-relaxed">{eventDetails.description}</p>
                            </div>
                        )}

                        {/* Event link */}
                        {(eventDetails.link || eventDetails.url) && (
                            <div>
                                <a
                                    href={eventDetails.link || eventDetails.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="inline-flex items-center justify-center w-full bg-black text-white px-6 py-3 font-medium text-center hover:bg-gray-800 transition-colors"
                                >
                                    Visit Official Event Page
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                    </svg>
                                </a>
                            </div>
                        )}
                    </div>

                    {/* Right column - image and map */}
                    <div className="lg:col-span-3 space-y-6 order-1 lg:order-2">
                        {/* Hero image */}
                        <div className="relative overflow-hidden">
                            {eventDetails.image_url ? (
                                <div className="bg-gray-100">
                                    <img
                                        src={eventDetails.image_url}
                                        alt={eventDetails.name}
                                        className="w-full h-auto"
                                        onError={(e) => {
                                            console.log("Image error:", eventDetails.image_url);
                                            e.target.onerror = null; // Prevent infinite loop
                                            e.target.src = FALLBACK_IMAGE;
                                        }}
                                    />
                                </div>
                            ) : (
                                <div className="bg-gray-200 flex items-center justify-center" style={{ height: '400px' }}>
                                    <span className="text-gray-400">No image available</span>
                                </div>
                            )}

                            {/* Date ribbon */}
                            {eventDetails.date && (
                                <div className="absolute bottom-0 right-0 bg-black text-white py-2 px-4">
                                    <div className="text-xs uppercase tracking-widest mb-1">Date</div>
                                    <div className="font-medium">
                                        {formatDate(eventDetails.date).split(',')[0]}
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Map section */}
                        {hasValidCoordinates && (
                            <div className="relative bg-white p-4 border border-gray-200" style={{ zIndex: 1 }}>
                                <div className="flex justify-between items-center mb-4">
                                    <h2 className="text-xl font-bold">Location</h2>

                                    <a
                                        href={`https://maps.google.com/?q=${eventDetails.latitude},${eventDetails.longitude}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-sm font-medium flex items-center text-gray-700 hover:text-black"
                                    >
                                        Open in Google Maps
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                        </svg>
                                    </a>
                                </div>

                                <div className="border border-gray-200 rounded overflow-hidden">
                                    <MapComponent
                                        markers={[marker]}
                                        height="320px"
                                        zoom={15}
                                        singleMode={true}
                                        center={[parseFloat(eventDetails.latitude), parseFloat(eventDetails.longitude)]}
                                    />
                                </div>

                                <p className="text-sm text-gray-500 mt-2">{eventDetails.address}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </main>
    );
}

export default EventDetailPage;
